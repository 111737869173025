// Styles for AwardsRecognition go here.
import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

export const StyledStickyCard = styled.div`
  ${rtl`
    .sticky-card-container {
      box-shadow: ${baseVariables.shadows.shadowSubtle04};
      z-index: 100;
    }
  `}
`;
