// Styles for PointsSection go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

export const StyledPointsEarned = styled.div`
  ${rtl`
    .points-earned {
      &-number {
        font-family: ${baseVariables.font.fontSwissCondensed};
        width: 100%;
        max-width: ${toRem(32)};
      }
      &-property {
        border-radius: ${baseVariables.border.borderInset02};
        background-color: ${baseVariables.color.neutral10};
      }
    }
  `}
`;
