// Styles for Container go here.
import styled from 'styled-components';
import { baseVariables } from '@marriott/mi-ui-library';
import rtl from 'styled-components-rtl';

//Below CSS for Tab component is to override CSS of common component
//This is targetting Tab component and Horizontal Mini which is used in flag section
export const StyledCobrandContainer = styled.div<{ bgImage: string }>`
  ${rtl`
    background: ${(props: { bgImage: string }) => (props.bgImage ? 'url(' + props.bgImage + ')' : 'none')};
    background-size: cover;
    background-repeat: no-repeat;

    .cobrand-container {
      &-tabs {
        .tab-wrapper {
          box-shadow: none;
        }
      }
      &__tile {
        .miniCardBody--wrapText {
          padding: 0;
          width: 100%;
        }
        picture {
          display: flex;
          align-items: center;
          .miniCardImage {
            height: auto;
          }
        }
        &--width {
          @media ${baseVariables.mediaQuery.xl} {
            width: 95%;
          }
        }
      }
    }
  `}
`;
